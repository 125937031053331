import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { FronteggProvider } from '@frontegg/react';

const contextOptions = {
    baseUrl: "https://binho-dev.frontegg.com",
    clientId: "1a7dda5b-f567-4152-9001-812758a51290"
};

ReactDOM.render(
    <FronteggProvider contextOptions={contextOptions} tracing={true} hostedLoginBox={true} authOptions={{ keepSessionAlive: true }}>
        <App />
    </FronteggProvider>,
    document.getElementById('root')
);
